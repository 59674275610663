import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import api from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import { getTotalWeight, setFrete } from "../redux/cartSlice";
import {} from "@headlessui/react";
import {
  updateShippingOptions,
  setShippingMethod,
  setShippingCost,
  setShippingAddress,
  setShippingTime,
} from "../redux/shippingSlice";
import {
  updateHolderAddress,
  updatePhase,
  updateWeight,
} from "../redux/orderSlice";
import { selectIsCompany } from "../redux/userSlice";
import RegisterAddress from "./Endereço/RegisterAddress";

const Frete = ({ phase }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [zip, setZip] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showFrete, setShowFrete] = useState(false);
  const dispatch = useDispatch();
  const totalWeight = useSelector(getTotalWeight);
  const isCompany = useSelector(selectIsCompany);
  const subtotal = useSelector((state) => state.cart.cart.subTotal);
  const user = useSelector((state) => state.user.user);
  const shippingAddress = useSelector(
    (state) => state.shipping.shippingAddress
  );
  const shippingOptions = useSelector(
    (state) => state.shipping.shippingOptions
  );
  const availableOptions = shippingOptions?.filter(
    (shippingOption) =>
      shippingOption.price != null &&
      shippingOption.price !== "" &&
      shippingOption.company.name === "Correios" &&
      shippingOption.name !== "PAC Centralizado" &&
      shippingOption.name !== "SEDEX Centralizado" &&
      shippingOption.name !== "Mini Centralizado" &&
      shippingOption.name !== "Mini Envios"
  );

  const [selected, setSelected] = useState(availableOptions[0]);

  const handleSelected = (option) => {
    const shippingM = {
      shippingMethod:
        option.name === "SEDEX" ? "CORREIOS_SEDEX" : "CORREIOS_PAC",
    };
    const shippingC = {
      shippingCost:
        (isCompany && subtotal >= 399 && option.name !== "SEDEX") ||
        (isCompany && subtotal >= 199 && option.name !== "SEDEX")
          ? Number(0)
          : Number(option.price),
    };
    const shippingT = { shippingTime: option.delivery_time };
    setSelected(option);
    dispatch(setShippingCost(shippingC));
    dispatch(
      setFrete(
        (isCompany && subtotal >= 399 && option.name !== "SEDEX") ||
          (isCompany && subtotal >= 199 && option.name !== "SEDEX")
          ? Number(0)
          : Number(option.price)
      )
    );
    dispatch(setShippingMethod(shippingM));
    dispatch(setShippingTime(shippingT));
    dispatch(updatePhase({ phase: 2 }));
    console.log(option);
  };
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const onSubmit = (data) => {
    const cleanZip = data.zip.replace(/-/g, "");
    try {
      api
        .post("me/shipping/calc", {
          to: {
            postal_code: cleanZip,
          },
          package: {
            height: 17,
            width: 17,
            length: 21,
            weight: totalWeight / 1000,
            insurance_value: 1,
          },
        })
        .then((res) => {
          // Utilize dispatch para atualizar as opções de frete
          dispatch(updateShippingOptions(res.data));
        });
    } catch (error) {}

    const modifiedData = {
      ...data, // Copia todos os dados existentes
      estado: data.state, // Modifica o campo estado
    };

    dispatch(updateHolderAddress(modifiedData));
    dispatch(updateWeight({ weight: totalWeight / 1000 }));
    dispatch(setShippingAddress(data));
  };

  const handleZipChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    if (value.length <= 8) {
      // Aplica a formatação enquanto digita
      const formattedValue = value.replace(/(\d{5})(\d)/, "$1-$2"); // Formata como 00000-000
      setZip(formattedValue);
    }
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const getTextClass = (index) => {
    return "text-gray-600";
  };

  return (
    <div className="w-full space-y-4 p-4 sm:p-10 md:max-w-lg">
      <h3 className="text-h3 leading-h3">Frete</h3>
      {phase === 0 && !user && (
        <p className="text-gray-500">
          Complete os passos anteriores para liberar o frete
        </p>
      )}
      {phase === 1 &&
        user &&
        !showFrete &&
        user.shippingAddress?.map((endereco, index) => (
          <div
            key={index}
            className={`m-2 p-4 max-w-sm border-2 rounded-lg shadow-md transition-all duration-300 ease-in-out ${"border-gray-300"}`}
          >
            <div className="flex justify-between h-[20px] items-center mb-4">
              <h3 className={`${getTextClass(index)} font-semibold`}>
                {endereco.name ? endereco.name : `Endereço ${index + 1}`}
              </h3>
            </div>
            <p className={getTextClass(index)}>
              {endereco.street}, {endereco.number}
              {endereco.complement ? `, ${endereco.complement}` : ""}
            </p>
            <p className={getTextClass(index)}>
              {endereco.neighborhood}, {endereco.city}
            </p>
            <p className={getTextClass(index)}>
              {endereco.state}, {endereco.country}
            </p>
            <p className={getTextClass(index)}>{endereco.zipCode}</p>
            <button
              type="button"
              className="flex items-center mt-2 justify-center rounded-3xl border border-transparent bg-black w-[200px] h-[40px] hover:bg-gray-900 px-6 py-1 text-base font-medium text-white shadow-sm"
              onClick={() => {
                const updatedHolderAddress = {
                  zip: endereco.zipCode,
                  address: endereco.street,
                  number: endereco.number,
                  neighborhood: endereco.neighborhood,
                  city: endereco.city,
                  state: endereco.state,
                  shippingAddressId: user.shippingAddress[index]?.id,
                  billingAddressId: user.billingAddress?.id,
                  phase: 1,
                };

                onSubmit(updatedHolderAddress);
                setShowFrete(true);
              }}
            >
              Use este endereço
            </button>
          </div>
        ))}
      {phase === 1 &&
      !showFrete &&
      (!user || user.shippingAddress.length === 0) &&
      availableOptions.length === 0 ? (
        <>
          <button
            type="button"
            onClick={openModal}
            className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded"
          >
            Cadastrar novo endereço
          </button>

          <RegisterAddress isOpen={isOpen} closeModal={closeModal} />
        </>
      ) : (
        showFrete && (
          <div className="space-y-4 w-full p-4">
            {shippingAddress && (
              <div className="flex justify-between">
                <div>
                  <p className="text-sm">Endereço de Entrega:</p>
                  <p className="text-xs text-gray-700 underline">
                    {shippingAddress.address}, Nº {shippingAddress.number},{" "}
                    {shippingAddress.neighborhood}, {shippingAddress.city}/
                    {shippingAddress.state}
                  </p>
                </div>
                <p
                  className="text-sm underline cursor-pointer"
                  onClick={() => {
                    dispatch(updatePhase({ phase: 1 }));
                    setShowFrete(false);
                  }}
                >
                  editar
                </p>
              </div>
            )}
            <div className="mx-auto w-full max-w-md">
              <RadioGroup
                by="name"
                value={selected}
                onChange={handleSelected}
                aria-label="Server size"
                className="space-y-4"
              >
                {availableOptions.map((plan) => (
                  <Radio
                    key={plan.name}
                    value={plan}
                    className="group relative flex cursor-pointer rounded-lg bg-white/5 py-4 px-5 text-white border border-black/50 data-[checked]:border-black transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-black"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="text-sm/6">
                        <p className="font-semibold text-black group-data-[checked]:text-white">
                          {plan.name}
                        </p>
                        <div className="flex gap-2 text-gray-700 group-data-[checked]:text-white">
                          <div>
                            Tempo de entrega: {plan.delivery_time ?? "N/A"} dias
                          </div>
                          <div aria-hidden="true">&middot;</div>
                          <div>
                            {(isCompany &&
                              subtotal >= 399 &&
                              plan.name === "PAC") ||
                            (isCompany &&
                              subtotal >= 199 &&
                              plan.name === "PAC")
                              ? "Frete Grátis"
                              : Number(plan.price)}
                          </div>
                        </div>
                      </div>
                      <CheckCircleIcon className="size-6 fill-white opacity-0 transition group-data-[checked]:opacity-100" />
                    </div>
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Frete;
