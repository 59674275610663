import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// Importe as ações do slice de order
import { updateShippingDetails } from "./orderSlice"; // ajuste o caminho conforme necessário

export const fetchShipping = createAsyncThunk(
  "shipping/fetchShipping",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      // Obtenha o estado atual do shipping
      const { shipping } = getState();

      // Desestruture os dados necessários do estado shipping
      const { shippingMethod, shippingPrice, shippingTime } = shipping;

      // Despache a ação para atualizar os valores da order
      dispatch(
        updateShippingDetails({
          shippingMethod,
          shippingPrice,
          shippingTime,
        })
      );

      return { shippingMethod, shippingPrice, shippingTime }; // Retorne os dados caso precise
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  shippingAddress: null,
  shippingMethod: "",
  shippingPrice: null,
  shippingTime: 0,
  shippingOptions: [], // Adicionando um estado para as opções de frete
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    setShippingAddress(state, action) {
      state.shippingAddress = action.payload;
    },
    setShippingMethod(state, action) {
      const { shippingMethod } = action.payload;
      state.shippingMethod = shippingMethod;
    },
    setShippingCost(state, action) {
      const { shippingCost } = action.payload;
      state.shippingPrice = shippingCost;
    },
    setShippingTime(state, action) {
      const { shippingTime } = action.payload;
      state.shippingTime = shippingTime;
    },
    clearShippingInfo(state) {
      state.shippingAddress = null;
      state.shippingMethod = null;
      state.shippingPrice = 0;
      state.shippingOptions = []; // Limpar também as opções de frete
    },
    updateShippingOptions(state, action) {
      state.shippingOptions = action.payload; // Atualiza as opções de frete
    },
  },
});

// Exportando as ações do slice de shipping
export const {
  setShippingAddress,
  setShippingMethod,
  setShippingCost,
  setShippingTime,
  clearShippingInfo,
  updateShippingOptions,
} = shippingSlice.actions;

// Exportando o reducer
export default shippingSlice.reducer;
