import { createSlice } from "@reduxjs/toolkit";

// Inicialmente, o estado da order
const initialState = { resume: {}, status: false };

const resumeOrder = createSlice({
  name: "resume",
  initialState,
  reducers: {
    setResume(state, action) {
      state.resume = action.payload;
      state.status = true; // Define status como verdadeiro ao definir o resumo
    },
    resetResume(state) {
      state.resume = {}; // Reseta o resumo
      state.status = false; // Define status como falso
    },
  },
});

// Exportando as ações e o reducer
export const { setResume, resetResume } = resumeOrder.actions;
export default resumeOrder.reducer;
