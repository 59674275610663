import { createSlice } from "@reduxjs/toolkit";

// Inicialmente, o estado da order
const initialState = {
  phase: 0,
  userId: null,
  items: [],
  billingAddressId: null,
  shippingAddressId: null,
  shippingMethod: null,
  shippingPrice: null,
  shippingTime: null,
  paymentDetails: {
    discountPercentage: 0,
    paymentGateway: "ASAAS",
    paymentMethod: null,
    creditCardDetails: null,
  },
  shippingPackage: {
    height: 17,
    width: 17,
    length: 21,
    weight: 0,
    insurance_value: 1,
  },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateHolderInfo: (state, action) => {
      const { id, name, email, cpfCnpj, phone, phase } = action.payload;

      // Atualizando holderInfo
      if (state.paymentDetails.creditCardDetails) {
        state.paymentDetails.creditCardDetails.holderInfo = {
          ...state.paymentDetails.creditCardDetails.holderInfo,
          name,
          email,
          cpfCnpj,
          phone,
        };
      }

      if (id) {
        state.userId = id;
      }
      if (state.paymentDetails.creditCardDetails) {
        // Atualizando holderName com o mesmo value do name
        state.paymentDetails.creditCardDetails.holderName = name;
      }
      if (phase) {
        // Atualizando a fase
        state.phase = phase;
      }
    },
    updateHolderAddress: (state, action) => {
      const {
        zip,
        address,
        number,
        neighborhood,
        city,
        estado,
        shippingAddressId,
        billingAddressId,
        phase,
      } = action.payload;

      if (state.paymentDetails.creditCardDetails) {
        // Atualizando holderInfo
        state.paymentDetails.creditCardDetails.holderInfo = {
          addressNumber: number,
          postalCode: zip,
          address,
          neighborhood,
          city,
          state: estado,
        };
      }

      // Atualizando os IDs de endereço, se presentes
      if (shippingAddressId) {
        state.shippingAddressId = shippingAddressId;
      }

      if (billingAddressId) {
        state.billingAddressId = billingAddressId;
      }
      if (phase) {
        state.phase = phase;
      }
    },

    updatePhase: (state, action) => {
      const { phase } = action.payload;
      state.phase = phase; // Atualizando apenas a propriedade phase
    },

    updateItems: (state, action) => {
      const { items } = action.payload;
      state.items = items;
    },
    updatePaymentMethod: (state, action) => {
      const { pMethod } = action.payload;
      state.paymentDetails.paymentMethod = pMethod;
    },
    updateShippingDetails: (state, action) => {
      const { shippingMethod, shippingPrice, shippingTime } = action.payload;
      state.shippingMethod = shippingMethod;
      state.shippingPrice = shippingPrice;
      state.shippingTime = shippingTime;
    },
    updateWeight: (state, action) => {
      const { weight } = action.payload;
      state.shippingPackage.weight = weight;
    },
    updateCreditCardDetails: (state, action) => {
      const { holderName, number, expiryMonth, expiryYear, ccv } =
        action.payload;
      state.paymentDetails.creditCardDetails = {
        holderName: holderName,
        number: number,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        ccv: ccv,
      };
    },
    clearOrder(state) {
      state.order = null;
    },
  },
});

// Exportando as ações e o reducer
export const {
  updateHolderInfo,
  updateHolderAddress,
  updatePhase,
  updateItems,
  updatePaymentMethod,
  updateShippingDetails,
  updateWeight,
  updateCreditCardDetails,
  clearOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
