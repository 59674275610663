import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import api from "../../api/api";

const Countdown = ({ user, order, payment }) => {
  const [isPaid, setIsPaid] = useState(false);
  const [copied, setCopied] = useState(false);
  const intervalRef = useRef(null);

  // Define a data de expiração para 10 minutos após a criação do componente
  const expirationDate = moment()
    .add(10, "minutes")
    .format("YYYY-MM-DD HH:mm:ss");

  const calculateTimeLeft = () => {
    const now = moment();
    const expiration = moment(expirationDate, "YYYY-MM-DD HH:mm:ss");
    const difference = expiration.diff(now);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / (1000 * 60)) % 60),
        segundos: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Limpa o intervalo no desmonte do componente
    return () => clearInterval(timer);
  }, []); // Executa apenas uma vez na montagem do componente

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval} className="text-sm text-center text-red underline">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  useEffect(() => {
    const checkOrderStatus = async () => {
      // Verifique se o tempo expirou
      if (
        timeLeft.dias === 0 &&
        timeLeft.horas === 0 &&
        timeLeft.minutos === 0 &&
        timeLeft.segundos === 0
      ) {
        clearInterval(intervalRef.current); // Limpa o intervalo se o tempo expirou
        return; // Sai da função
      }

      try {
        const response = await api(`/orders/${order.id}`);
        const { paymentStatus } = response.data.order;

        if (
          paymentStatus === "PAYMENT_CONFIRMED" ||
          paymentStatus === "PAYMENT_RECEIVED"
        ) {
          setIsPaid(true);
          clearInterval(intervalRef.current); // Limpa o intervalo se o pagamento for confirmado
          return; // Sai da função
        }
      } catch (error) {
        console.error("Error checking order status:", error);
      }
    };

    intervalRef.current = setInterval(checkOrderStatus, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, [timeLeft]); // Adicionando order.id como dependência

  return isPaid ? (
    <div className="text-center mt-4 mb-8">
      <p className="text-2xl text-emerald-600 font-semibold py-4">
        O PIX foi pago com sucesso!
      </p>
      <p className="py-2">
        Caso já tenha realizado o pagamento, não se preocupe, seu pagamento está
        em processamento, mandaremos um e-mail quando seu pedido for aprovado.
      </p>
      <p className="underline text-blue-400">{user.email}</p>
    </div>
  ) : timerComponents.length ? (
    <div className="items-center w-full transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle transition-all">
      <div className="mb-4">
        <h3 className="text-lg mb-2 text-center font-medium leading-6 text-gray-900">
          Pague com PIX no app ou site do seu banco utilizando o QR Code ou
          copiando o código.
        </h3>
        <div className="flex flex-col md:flex-row w-full justify-evenly">
          <div className="flex flex-col items-center space-y-4">
            {/* Container para o QR Code e payload */}
            <div className="p-2 bg-gray-100 rounded">
              {/* Verifica se a imagem codificada está presente e é uma string válida */}
              {payment?.pix?.encodedImage ? (
                <img
                  className="w-[220px] h-[220px]" // Ajuste o tamanho conforme necessário
                  src={`data:image/png;base64,${payment.pix.encodedImage}`}
                  alt="QR Code de Pagamento"
                />
              ) : (
                <p className="text-gray-500">
                  Imagem do QR Code não disponível
                </p>
              )}
            </div>
            {/* Container para o botão Copiar */}
            <div className="text-sm">
              <CopyToClipboard
                text={payment?.pix?.payload}
                onCopy={() => setCopied(true)}
              >
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
                  Copiar Código Pix
                </button>
              </CopyToClipboard>
            </div>{" "}
            {copied && (
              <p className="text-lime-600 mt-2">Código copiado com sucesso!</p>
            )}
          </div>
          <ol className="list-decimal text-left list-inside p-4 text-sm">
            <li>Acesse o site ou APP do seu banco;</li>
            <li>Escolha pagar com PIX;</li>
            <li>Escaneie o QR Code ou copie o código do PIX;</li>
            <li>Confirme o pagamento.</li>
          </ol>
        </div>
      </div>
      <div className="flex space-x-1">
        <p>Tempo restante para pagar:</p>
        <span>{timerComponents}</span>
      </div>
    </div>
  ) : (
    <div className="text-center mt-4 mb-8">
      <p>Infelizmente, o prazo para pagar com código PIX acabou.</p>
      <p>
        Caso já tenha realizado o pagamento, não se preocupe, seu pagamento está
        em processamento, manderemos um e-mail quando seu pedido for aprovado.
      </p>
      <p className="underline text-blue-400">{user.email}</p>
    </div>
  );
};

export default Countdown;
