import { useState, useEffect } from "react";
import Countdown from "./Countdown";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../redux/cartSlice";
import { clearOrder } from "../../redux/orderSlice";
import { clearUser } from "../../redux/userSlice";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const resume = useSelector((state) => state.resume?.resume);
  const status = useSelector((state) => state.resume.status);

  const redirectToInvoice = (invoiceUrl) => {
    window.open(invoiceUrl, "_blank");
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (status) {
      dispatch(clearCart());
      dispatch(clearOrder());
      dispatch(clearUser());
    }
  }, [status]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  return (
    <section className="flex justify-center w-full">
      <div className="w-full md:max-w-[60%] bg-white p-2 ">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-blue-600">
            Falta pouco! Seu pedido foi concluído.
          </h1>
          <p className="text-lg">
            O pagamento do seu pedido está sendo processado, você receberá via
            e-mail as atualizações no seu pedido.
          </p>
        </div>
        {resume?.paymentMethod === "PIX" && (
          <Countdown
            user={user}
            order={resume}
            payment={resume?.asaasPaymentDetails}
          />
        )}
        {resume?.paymentMethod === "BANK_SLIP" && (
          <div className="text-center mt-10">
            <p className="font-bold text-2xl text-[#fa1414] mb-2">
              Vencimento:{" "}
              <span className="underline">
                {new Date(
                  Date.UTC(
                    parseInt(
                      resume?.asaasPaymentDetails.bankSlip.dueDate.substring(
                        0,
                        4
                      )
                    ),
                    parseInt(
                      resume?.asaasPaymentDetails.bankSlip.dueDate.substring(
                        5,
                        7
                      )
                    ) - 1,
                    parseInt(
                      resume.asaasPaymentDetails.bankSlip.dueDate.substring(
                        8,
                        10
                      )
                    )
                  )
                ).toLocaleDateString("pt-BR")}
              </span>
            </p>

            <button
              className="text-2xl text-[#312e81] hover:underline"
              onClick={() =>
                redirectToInvoice(resume.asaasPaymentDetails.bankSlip.url)
              }
            >
              Visualizar Boleto
            </button>
          </div>
        )}
        <div className="mt-4">
          <div className="flex flex-col justify-between border-t border-gray-200 py-4 space-y-6">
            <h3 className="text-black font-semibold py-2">
              Detalhe dos Produtos:
            </h3>
            {resume?.items?.map((item, index) => (
              <div
                key={item.id || index}
                className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow-md p-6"
              >
                <img
                  src={item.imgUrl} // Caminho da imagem ou imagem padrão
                  alt={item.name}
                  className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-lg mr-4"
                />
                <div className="flex-grow">
                  <h4 className="text-lg font-semibold text-gray-800">
                    {item.name}
                  </h4>
                  <p className="text-sm text-gray-600 my-2">
                    {item.shortDesc || "Descrição do Item"}
                  </p>
                  <dl className="flex flex-wrap items-center mt-2 space-x-4 text-sm text-gray-700">
                    <div className="flex items-center">
                      <span>Quantidade:</span>
                      <span className="ml-1 font-medium">{item.quantity}</span>
                    </div>
                    <div className="w-px h-5 bg-gray-300 mx-2" />
                    <div className="flex items-center">
                      <span>Preço Unitário:</span>
                      <span className="ml-1 font-medium">
                        {formatCurrency(item.price)}
                      </span>
                    </div>
                    <div className="w-px h-5 bg-gray-300 mx-2" />
                    <div className="flex items-center">
                      <span>Subtotal:</span>
                      <span className="ml-1 font-medium">
                        {formatCurrency(item.price * item.quantity)}
                      </span>
                    </div>
                  </dl>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <div className="flex flex-col p-4 space-y-5 md:space-y-0 md:flex-row md:justify-between items-center border-t border-gray-200 pb-4">
            <div className="w-full md:w-fit">
              <p className="font-medium">Endereço de Entrega</p>
              <h4>{resume?.user?.name}</h4>
              <p className="text-sm">
                {resume?.orderShippingAddress?.street},{" "}
                {resume?.orderShippingAddress?.number}
                {resume?.orderShippingAddress?.complement
                  ? `, ${resume?.orderShippingAddress?.complement}`
                  : ""}
              </p>
              <p className="text-sm">
                {resume?.orderShippingAddress?.neighborhood},{" "}
                {resume?.orderShippingAddress?.city},{" "}
                {resume?.orderShippingAddress?.state}
              </p>{" "}
              <p className="text-sm">
                {resume?.orderShippingAddress?.country},{" "}
                {resume?.orderShippingAddress?.zipCode}
              </p>{" "}
            </div>
            <div className="w-full md:w-fit">
              <p className="font-medium">Endereço de Cobrança</p>
              <h4>{resume?.user?.name}</h4>
              <p className="text-sm">
                {resume?.orderBillingAddress?.street},{" "}
                {resume?.orderBillingAddress?.number}
                {resume?.orderBillingAddress?.complement
                  ? `, ${resume?.orderBillingAddress?.complement}`
                  : ""}
              </p>
              <p className="text-sm">
                {resume?.orderBillingAddress?.neighborhood},{" "}
                {resume?.orderBillingAddress?.city},{" "}
                {resume?.orderBillingAddress?.state}
              </p>{" "}
              <p className="text-sm">
                {resume?.orderBillingAddress?.country},{" "}
                {resume?.orderBillingAddress?.zipCode}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex flex-col p-4 space-y-5 md:space-y-0 md:flex-row justify-between md:items-center border-t border-gray-200 py-4">
            <div>
              <p className="font-medium">Método de Pagamento</p>
              {resume?.paymentMethod === "CREDIT_CARD" && (
                <>
                  <p>
                    Cartão de Crédito{" "}
                    {resume?.asaasPaymentDetails?.creditCard
                      .installmentCount && (
                      <p>
                        {
                          resume?.asaasPaymentDetails?.creditCard
                            .installmentCount
                        }
                        x de{" "}
                        {formatCurrency(
                          resume?.asaasPaymentDetails?.creditCard
                            .installmentValue
                        )}
                      </p>
                    )}
                  </p>
                  <p>
                    {resume?.asaasPaymentDetails?.creditCard.creditCardBrand}{" "}
                    ************
                    {resume?.asaasPaymentDetails?.creditCard.creditCardNumber}
                  </p>

                  <p
                    className="text-sm text-blue-300 underline cursor-pointer"
                    onClick={() =>
                      redirectToInvoice(resume?.asaasPaymentDetails?.invoiceUrl)
                    }
                  >
                    Visualizar fatura Nº{" "}
                    {resume?.asaasPaymentDetails?.invoiceNumber}
                  </p>
                </>
              )}
              {resume?.paymentMethod === "BANK_SLIP" && (
                <>
                  <p>Boleto</p>
                  <p>
                    Vencimento:{" "}
                    {new Date(
                      resume?.asaasPaymentDetails?.dueDate
                    ).toLocaleDateString("pt-BR")}
                  </p>
                  <button
                    className="text-base text-blue-300 underline cursor-pointer"
                    onClick={() =>
                      redirectToInvoice(
                        resume?.asaasPaymentDetails?.bankSlipUrl
                      )
                    }
                  >
                    Visualizar Boleto
                  </button>
                </>
              )}
              {resume?.paymentMethod === "PIX" && (
                <>
                  <h3>Pix</h3>
                </>
              )}
            </div>
            <div>
              <p className="font-medium">Método de Entrega</p>
              <p>Correios</p>
              {resume?.shippingTime ? (
                <p className="text-sm">
                  {resume?.shippingTime} dias úteis após a postagem
                </p>
              ) : (
                <p className="text-sm">3-5 dias úteis após a postagem</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="border-t border-gray-200 p-4">
            <div className="flex justify-between">
              <p>Subtotal</p>
              <p>{formatCurrency(resume?.subTotal)}</p>
            </div>
            {resume?.paymentMethod === "PIX" && (
              <div className="flex justify-between text-rose-400">
                <p>Pix 5%</p>
                <p>
                  - {formatCurrency((resume?.subTotal.toFixed(2) * 5) / 100)}
                </p>
              </div>
            )}
            {resume?.discount > 0 && (
              <div className="flex justify-between text-rose-400">
                <p>Desconto</p>
                <p>- {formatCurrency(resume?.discount)}</p>
              </div>
            )}
            <div className="flex justify-between">
              <p>Frete</p>
              <p>
                {resume?.shippingPrice === 0
                  ? "Frete Grátis"
                  : formatCurrency(resume?.shippingPrice)}
              </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total</p>
              <p>{formatCurrency(resume?.total)}</p>
            </div>
            {resume?.asaasPaymentDetails?.creditCard.installmentCount && (
              <div className="flex justify-between">
                <p>Parcelado</p>{" "}
                <p>
                  {resume?.asaasPaymentDetails?.creditCard.installmentCount}x{" "}
                  {formatCurrency(
                    resume?.asaasPaymentDetails?.creditCard.installmentValue
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderSummary;
