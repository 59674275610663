import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import { setResume } from "../../redux/resumeSlice";

const Pix = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const [errorMessage, setErrorMessage] = useState(null);
  const cart = useSelector((state) => state.cart.cart);
  const order = useSelector((state) => state.order);
  const onSubmit = async () => {
    try {
      setIsLoading(true); // Inicia o carregamento
      setErrorMessage(null); // Reset error message

      const { creditCardDetails, ...orderWithoutCreditCard } =
        order.paymentDetails; // Desestrutura creditCardDetails
      const newOrder = {
        ...order,
        paymentDetails: orderWithoutCreditCard, // Adiciona o paymentDetails sem creditCardDetails
      };
      const response = await api.post("/orders", newOrder);

      if (response.status === 201) {
        dispatch(setResume(response.data.order));
      } else {
        throw new Error("Erro ao realizar a compra");
      }
    } catch (error) {
      console.error("Erro:", error);
      setErrorMessage("Erro ao realizar o pagamento. Tente novamente."); // Set error message
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md space-y-4">
      <h2 className="text-xl font-bold mb-2">Pagamento com Pix</h2>
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}

      <ol className="list-decimal list-inside space-y-4 p-4 text-sm">
        <li>Clique em "Pagar".</li>
        <li>Na tela seguinte, copie o código.</li>
        <li>
          No App do seu banco, selecione a opção Pagar com o Pix e cole o código
          que você copiou.
        </li>
        <li>Confira os dados e confirme o pagamento.</li>
        <li>Pronto, o pagamento foi feito com sucesso!</li>
      </ol>

      <button
        type="button"
        onClick={onSubmit}
        disabled={isLoading || cart.items.length === 0} // Desativa o botão durante o carregamento
        className={`w-full bg-black text-white font-bold py-2 rounded-md 
          hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-black ${
            isLoading || cart.items.length === 0
              ? "cursor-not-allowed opacity-70"
              : ""
          }`}
      >
        {isLoading ? (
          <svg
            className="animate-spin h-5 w-5 text-white mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8z"
            ></path>
          </svg>
        ) : cart.items.length === 0 ? (
          "Carrinho Vazio"
        ) : (
          "Pagar"
        )}
      </button>
    </div>
  );
};

export default Pix;
