import React, { useState } from "react";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice";

const LoginModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [enviando, setEnviando] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setEnviando(true);
      const response = await api.post("/store/sessions", {
        username: data.username,
        password: data.password,
      });

      if (response.status === 200 && response.data.token) {
        const token = response.data.token;

        // Armazena o token JWT em um cookie
        Cookies.set("token", token, { expires: 7 }); // O token expira em 7 dias, ajuste conforme necessário
        api.defaults.headers.Authorization = `Bearer ${token}`;

        // Busca os dados do usuário usando o token
        const res = await api.get(`/me`);
        dispatch(setUser(res.data));
        setEnviando(false);
      }
    } catch (error) {
      setEnviando(false);
      console.error("Erro ao fazer login:", error); // Para tratamento de erro no login
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed m-0 inset-0 w-screen h-screen z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-80 p-6 relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          ✕
        </button>
        <h2 className="text-xl font-semibold text-center mb-4">
          Informe o login da sua Conta ZP
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              {...register("username", { required: "Email é obrigatório" })}
              className="mt-2 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm text-sm text-gray-900"
              placeholder="Digite seu email"
            />
            {errors.username && (
              <p className="text-red-500 text-sm">{errors.username.message}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700">Senha</label>
            <input
              type="password"
              {...register("password", { required: "Senha é obrigatória" })}
              className="mt-2 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm text-sm text-gray-900"
              placeholder="Digite sua senha"
            />
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password.message}</p>
            )}
          </div>
          <button
            type="submit"
            disabled={enviando} // Desativa o botão durante o carregamento
            className={`w-full bg-black text-white font-bold py-2 rounded-md 
          hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-black ${
            enviando ? "cursor-not-allowed opacity-70" : ""
          }`}
          >
            {enviando ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            ) : (
              "Acessar Conta"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
