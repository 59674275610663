import "./App.css";
import ProductSummary from "./components/ProductSummary";
import UserProfile from "./components/UserProfile";
import Frete from "./components/Frete";
import { useDispatch, useSelector } from "react-redux";
import CheckoutForm from "./components/CheckoutForm";
import { useEffect, useState } from "react";
import { fetchUser, selectIsCompany } from "./redux/userSlice";
import { fetchCart } from "./redux/cartSlice";
import { updateHolderInfo, updateItems, updatePhase } from "./redux/orderSlice";
import { fetchShipping } from "./redux/shippingSlice";
import OrderSummary from "./components/Summary/OrderSummary";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const orderPhase = useSelector((state) => state.order.phase);
  const user = useSelector((state) => state.user.user);
  const shippingMethod = useSelector((state) => state.shipping.shippingMethod);
  const order = useSelector((state) => state.order);
  const resume = useSelector((state) => state.resume);
  const cart = useSelector((state) => state.cart.cart);
  const itemsInCart = useSelector((state) => state.cart.cart.items);
  const status = useSelector((state) => state.user.status);

  useEffect(() => {
    const fetchData = async () => {
      if (status === "idle") {
        try {
          await dispatch(fetchUser()).unwrap();
        } catch (error) {
          console.error("Failed to fetch user:", error);
        }
      }
    };
    fetchData();
  }, [dispatch, status]);

  useEffect(() => {
    const updateUserCart = async () => {
      if (user) {
        dispatch(updateHolderInfo(user));
        dispatch(updatePhase({ phase: 1 }));
      }
      try {
        await dispatch(fetchCart(user ? user.isCompany : false)).unwrap();
        setLoading(false); // Set loading to false after all operations
      } catch (error) {
        setLoading(false); // Set loading to false after all operations
        console.error("Failed to fetch cart:", error);
      }
      setLoading(false); // Set loading to false after all operations
    };
    updateUserCart();
  }, [user, dispatch]);

  useEffect(() => {
    const i = { items: itemsInCart };
    dispatch(updateItems(i));
  }, [itemsInCart, dispatch]);

  useEffect(() => {
    if (shippingMethod) {
      dispatch(fetchShipping());
    }
  }, [shippingMethod]);

  useEffect(() => {
    console.log("Order em App.js:", order);
  }, [order]);

  useEffect(() => {
    console.log("Cart em App.js:", cart);
  }, [cart]);

  useEffect(() => {
    console.log("Resume em App.js", resume);
  }, [resume]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex items-center space-x-2">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600"></div>
          <span className="text-lg text-gray-700">Carregando...</span>
        </div>
      </div>
    );
  }

  return resume.status ? (
    <main className="font-sans flex flex-col items-center justify-center h-full">
      <div className="flex justify-center align-middle items-center my-4 w-3/4 md:w-[320px] ">
        <img
          alt="logo"
          src="https://www.zpcosmeticos.com.br/static/media/Logo%20Zp%20Horizontal.8074481b9c7624db6b1d0818bcf777f9.svg"
        />
      </div>
      <OrderSummary />
    </main>
  ) : (
    <main className="font-sans flex flex-col md:flex-row-reverse h-full md:h-screen items-center justify-center">
      <div className="w-full md:w-[45%] md:overflow-auto bg-gray-700">
        <ProductSummary />
      </div>
      <section className="flex flex-col bg-[#f9fafb] w-full h-full md:w-[55%] md:overflow-auto items-center">
        <div className="flex justify-center items-center w-3/4 md:w-[320px] mt-4 md:pt-4">
          <img
            alt="logo"
            src="https://www.zpcosmeticos.com.br/static/media/Logo%20Zp%20Horizontal.8074481b9c7624db6b1d0818bcf777f9.svg"
          />
        </div>
        {/* The following components will stack below the logo */}
        <UserProfile phase={orderPhase} />
        <Frete phase={orderPhase} />
        <CheckoutForm phase={orderPhase} />
      </section>
    </main>
  );
}

export default App;
