import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userSlice";

const RegisterAddress = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const [cadastrado, setCadastrado] = useState({
    shipping: false,
    billing: false,
  });
  const [enviando, setEnviando] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [isChecked, setIsChecked] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const Country = watch("country");

  useEffect(() => {
    if (cadastrado.shipping && cadastrado.billing) {
      api.get("me").then((res) => {
        if (res.status === 200) {
          dispatch(setUser(res.data));
        }
      });
    }
  }, [cadastrado, dispatch]);

  // Função para limpar o valor do CEP
  const cleanZipCode = (zipCode) => {
    return zipCode.replace("-", "");
  };

  // Função para tratar a submissão do formulário
  const onSubmitHandler = async (formData) => {
    const cleanedZipCode = cleanZipCode(formData.zipCode);
    setEnviando(true);
    try {
      const response = await api.post("/users/shippingAddresses", {
        ...formData,
        number: parseInt(formData.number, 10),
        zipCode: cleanedZipCode,
        userId: user.id, // Assuming 'user' prop contains 'id'
      });

      if (response.status === 201) {
        setCadastrado((cadastrado) => ({ ...cadastrado, shipping: true }));
        if (isChecked && user.billingAddress === null) {
          await handleBillingAddress(formData, user, cleanedZipCode);
          setEnviando(true);
        }
        setEnviando(true);
      } else {
        setEnviando(true);
        throw new Error(
          "Não foi possível cadastrar o endereço de entrega no momento. Tente novamente mais tarde."
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  // Função para criar o endereço de cobrança
  const handleBillingAddress = async (formData, user, cleanedZipCode) => {
    try {
      const response = await api.post("/users/billingAddresses", {
        ...formData,
        number: parseInt(formData.number, 10),
        zipCode: cleanedZipCode,
        userId: user.id,
      });

      if (response.status === 201) {
        setCadastrado((cadastrado) => ({ ...cadastrado, billing: true }));
      } else {
        setEnviando(true);
        throw new Error(
          "Não foi possível cadastrar o endereço de cobrança no momento. Tente novamente mais tarde."
        );
      }
    } catch (error) {
      setEnviando(true);
      handleError(error);
    }
  };

  // Função para lidar com erros
  const handleError = (error) => {
    console.error("Failed to submit address:", error);
    let errorMessage = "Failed to submit address. Please try again.";

    if (error.response) {
      if (
        error.response.status === 409 &&
        error.response.data.message === "Shipping address already exists."
      ) {
        errorMessage = "Este endereço já está cadastrado.";
      } else {
        switch (error.response.status) {
          case 400:
            errorMessage =
              "Pedido inválido. Verifique os dados e tente novamente.";
            break;
          case 401:
            errorMessage =
              "Não autorizado. Por favor, faça login e tente novamente.";
            break;
          case 403:
            errorMessage =
              "Acesso negado. Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            errorMessage =
              "Recurso não encontrado. Por favor, tente novamente.";
            break;
          case 500:
            errorMessage = "Erro no servidor. Tente novamente mais tarde.";
            break;
          default:
            errorMessage = "Algo deu errado. Por favor, tente novamente.";
        }
      }
    } else if (error.request) {
      errorMessage =
        "Sem resposta do servidor. Verifique sua conexão com a internet.";
    } else {
      errorMessage = "Erro na configuração da solicitação. Tente novamente.";
    }
  };

  // Example of setting a value programmatically, might be used in custom functions like handleCEP
  const handleCEP = async (cep, t) => {
    try {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json`);
      const cleanedZipCode = cleanZipCode(cep);
      if (response.data) {
        if (t === "ship") {
          setValue("street", response.data.logradouro);
          setValue("neighborhood", response.data.bairro);
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
          setValue("country", "Brasil");
          setValue("ibgeId", response.data.ibge);
          setValue("zipCode", cleanedZipCode);
        } else if (t === "bill") {
          setValue("street", response.data.logradouro);
          setValue("neighborhood", response.data.bairro);
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
          setValue("country", "Brasil");
          setValue("ibgeId", response.data.ibge);
          setValue("zipCode", cleanedZipCode);
        }
      } else if (response.data.error) {
        // Handle case when response.data or response.data.result is not available
        if (response.data.code === 404) {
        } else {
          console.error("Error fetching CEP data:");
        }
      }
    } catch (error) {
      // Error handling
      if (error.request.status === 404) {
      } else {
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // Aqui você pode adicionar lógica adicional que deve acontecer quando o checkbox é marcado ou desmarcado
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Cadastro de Endereço
                </Dialog.Title>
                <form
                  className="space-y-6 mt-6"
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                    <div className="sm:col-span-2">
                      <div className="flex justify-center">
                        <div className="flex flex-col mx-auto">
                          <label
                            htmlFor="zipCode"
                            className="block text-md text-gray-700 font-normal"
                          >
                            CEP
                          </label>
                          <input
                            {...register("zipCode", {
                              required: "CEP é obrigatório",
                            })}
                            type="text"
                            name="zipCode"
                            className="mt-1 block w-full rounded-md shadow-sm p-3"
                            placeholder="XXXXX-XXX"
                          />
                          {errors.zipCode && (
                            <p className="text-red-500">
                              {errors.zipCode.message}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="mx-auto my-1 flex justify-center items-center">
                            <button
                              type="button"
                              onClick={() =>
                                handleCEP(watch("zipCode"), "ship")
                              }
                              className="border sm:mt-7 border-blue-200 text-black text-lg py-1 px-4 rounded hover:border-blue-200 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
                            >
                              Consultar CEP
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Country ? (
                    <>
                      {/* Numero */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Numero
                        </label>
                        <div>
                          <input
                            type="text"
                            name="number"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("number", {
                              required: "Número do endereço é obrigatório",
                            })}
                            placeholder="123"
                          />
                          {errors.number && (
                            <p style={{ color: "red" }}>
                              {errors.number.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/*Complemento */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="complement"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Complemento
                        </label>
                        <div>
                          <input
                            type="text"
                            name="complement"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("complement", {
                              required: "Complemento é obrigatório",
                            })}
                            placeholder="Apto 101, Casa A, Ao Lado do Mercado Bar"
                          />
                          {errors.complement && (
                            <p style={{ color: "red" }}>
                              {errors.complement.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Rua */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="street"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rua
                        </label>
                        <div>
                          <input
                            type="text"
                            name="street"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("street", {
                              required: "Complemento é obrigatório",
                            })}
                            placeholder="Rua da Sua Casa"
                          />
                          {errors.street && (
                            <p style={{ color: "red" }}>
                              {errors.name.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Bairro */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="neighborhood"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bairro
                        </label>
                        <div>
                          <input
                            type="text"
                            name="neighborhood"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("neighborhood", {
                              required: "Bairro é obrigatório",
                            })}
                            placeholder="Seu Bairro"
                          />
                          {errors.neighborhood && (
                            <p style={{ color: "red" }}>
                              {errors.neighborhood.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/*Cidade */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cidade
                        </label>
                        <div>
                          <input
                            type="text"
                            name="city"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("city", {
                              required: "Bairro é obrigatório",
                            })}
                            placeholder="Sua Cidade"
                          />
                          {errors.city && (
                            <p style={{ color: "red" }}>
                              {errors.city.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Estado */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Estado
                        </label>
                        <div>
                          <input
                            type="text"
                            name="state"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("state", {
                              required: "Bairro é obrigatório",
                            })}
                            placeholder="Seu Estado"
                          />
                          {errors.state && (
                            <p style={{ color: "red" }}>
                              {errors.state.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* País */}
                      <div className="mb-3 row">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          País
                        </label>
                        <div>
                          <input
                            type="text"
                            name="country"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                            {...register("country", {
                              required: "Bairro é obrigatório",
                            })}
                            placeholder="Seu País"
                          />
                          {errors.country && (
                            <p style={{ color: "red" }}>
                              {errors.country.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Checkbox para usar mesmo endereço como BillingAddress */}
                      <div className="flex items-center">
                        <label
                          htmlFor="billingAddressCheckbox"
                          className="relative flex cursor-pointer items-center rounded-full p-3"
                        >
                          <input
                            id="billingAddressCheckbox"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-600 checked:bg-blue-600 checked:before:bg-blue-600 hover:before:opacity-10"
                          />
                          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3.5 w-3.5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              strokeWidth="1"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </label>
                        <label
                          className="mt-px cursor-pointer select-none font-light text-gray-700"
                          htmlFor="login"
                        >
                          Desejo utilizar este endereço como endereço de entrega
                          e de cobrança
                        </label>
                      </div>
                    </>
                  ) : null}

                  <button
                    type="submit"
                    disabled={enviando} // Desativa o botão durante o carregamento
                    className={`w-full bg-black text-white font-bold py-2 rounded-md 
          hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-black ${
            enviando ? "cursor-not-allowed opacity-70" : ""
          }`}
                  >
                    {enviando ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8z"
                        ></path>
                      </svg>
                    ) : (
                      "Cadastrar Endereço"
                    )}
                  </button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegisterAddress;
