import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  increaseQuantity,
  decreaseQuantity,
  removeItem,
  setSubTotal,
  setFrete,
  setTotal,
} from "../redux/cartSlice"; // ajuste o caminho conforme necessário
import { selectIsCompany } from "../redux/userSlice";

const ProductSummary = () => {
  const dispatch = useDispatch();
  const itemsInCart = useSelector((state) => state.cart.cart.items);
  const paymentMethod = useSelector(
    (state) => state.order.paymentDetails.paymentMethod
  );
  const carrinho = useSelector((state) => state.cart.cart);
  const cartStatus = useSelector((state) => state.cart.status);
  const shippingPrice = useSelector((state) => state.shipping.shippingPrice);
  const isCompany = useSelector(selectIsCompany);

  const calculateSubtotal = () => {
    return itemsInCart.reduce((total, item) => {
      let price;

      // Verifica se o produto está com desconto e aplica o preço apropriado
      if (isCompany) {
        // Para usuários do tipo 'company'
        if (
          item.vendorPriceNew > 0 &&
          new Date(item.discountVendorDueDate) > new Date()
        ) {
          price = item.vendorPriceNew; // Preço com desconto para empresas
        } else {
          price = item.vendorPrice; // Preço normal para empresas
        }
      } else {
        // Para clientes normais
        if (
          item.customerPriceNew > 0 &&
          new Date(item.discountCustomerDueDate) > new Date()
        ) {
          price = item.customerPriceNew; // Preço com desconto para clientes
        } else {
          price = item.customerPrice; // Preço normal para clientes
        }
      }

      // Acumula o preço total
      return total + price * item.quantity;
    }, 0);
  };

  useEffect(() => {
    if (itemsInCart.length > 0) {
      dispatch(setSubTotal(calculateSubtotal()));
    }
  }, [itemsInCart]);

  useEffect(() => {
    if (shippingPrice >= 0) {
      dispatch(setTotal(calculateTotal()));
    }
  }, [shippingPrice]);

  const calculateTotal = () => {
    let total = 0;
    let subTotal = calculateSubtotal();
    let shippingCostFloat = Number(shippingPrice); // Garante que seja float, e previne NaN com valor default 0
    total = subTotal + shippingCostFloat;
    return Number(total.toFixed(2));
  };

  const isValidDate = (date) => {
    return new Date(date) >= new Date();
  };

  const showPrice = (product) => {
    if (!isCompany) {
      // Lógica para cliente
      if (
        product.customerPriceNew &&
        isValidDate(product.discountCustomerDueDate)
      ) {
        return product.customerPriceNew; // Mostra customerPriceNew
      }
      return product.customerPrice; // Mostra customerPrice
    } else {
      // Lógica para vendedor
      if (
        product.vendorPriceNew &&
        isValidDate(product.discountVendorDueDate)
      ) {
        return product.vendorPriceNew; // Mostra vendorPriceNew
      }
      return product.vendorPrice; // Mostra vendorPrice
    }
  };

  const handleIncrease = (id) => {
    dispatch(increaseQuantity(id));
  };

  const handleDecrease = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  return (
    <section className="w-full h-full md:h-screen p-4">
      <div>
        <h1 className="text-white text-2xl font-semibold md:py-20 text-center py-4">
          Resumo do pedido
        </h1>
        {cartStatus === "loading" ? ( // Verifique se os produtos estão carregando
          <div className="text-white text-base font-semibold md:py-20 text-center py-4">
            <span>Carregando produtos...</span>
          </div>
        ) : cartStatus === "failed" || itemsInCart.length === 0 ? ( // Verifique se não há produtos
          <div className="text-white text-base font-semibold md:py-20 text-center py-4">
            <span>Nenhum produto no carrinho!</span>
          </div>
        ) : (
          <ul>
            {itemsInCart?.map((produto, index) => (
              <li
                key={index}
                className="flex flex-col items-start md:items-center justify-between py-4"
              >
                {/* Imagem e Detalhes do Produto */}
                <div className="flex items-start md:items-center w-full">
                  <img
                    src={produto.imgUrl}
                    alt={produto.name}
                    className="w-20 h-20 rounded-lg mr-4 object-cover"
                  />
                  <div className="flex flex-col w-full">
                    <h3 className="text-lg font-semibold text-white mb-1">
                      {produto.name}
                    </h3>
                    <p className="text-sm text-white mb-1">
                      {formatCurrency(showPrice(produto))}
                    </p>
                    <div className="flex justify-between items-center text-sm text-white mb-2">
                      <div className="flex items-center">
                        <p className="mr-4">Qtd: {produto.quantity}</p>
                        <div className="flex items-center gap-x-2">
                          <button
                            type="button"
                            className={`text-xl md:text-base font-medium`}
                            onClick={() =>
                              handleDecrease(produto.orderProductId)
                            }
                            disabled={produto.quantity === 1}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="size-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            type="button"
                            className="text-xl md:text-base font-medium"
                            onClick={() =>
                              handleIncrease(produto.orderProductId)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="size-5"
                            >
                              <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="font-medium"
                        onClick={() => handleRemove(produto.orderProductId)}
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-b border-[#a5b4fc50] my-4 h-1 w-full md:w-[90%]"></div>
              </li>
            ))}
          </ul>
        )}
        {/* 
        <div className="text-white p-y-4">
          <p>Cupom</p>
        </div>
          */}
      </div>

      <div>
        <ul className="text-white">
          <li className="flex justify-between">
            <p>Subtotal</p>
            <p>{formatCurrency(carrinho.subTotal)}</p>
          </li>
          <li className="flex justify-between">
            <p>Frete</p>
            <p>
              {shippingPrice === 0
                ? "Frete Grátis"
                : formatCurrency(carrinho.frete)}
            </p>
          </li>
          {paymentMethod === "PIX" && (
            <li className="flex justify-between">
              <p>Desconto Pix</p>
              <p>-{formatCurrency(carrinho.subTotal * 0.05)}</p>
            </li>
          )}
          <li className="flex justify-between">
            <p>Total</p>
            <p>
              {carrinho.total > 0
                ? formatCurrency(carrinho.total)
                : formatCurrency(carrinho.subTotal)}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ProductSummary;
