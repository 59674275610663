import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import CreditCard from "./paymentOptions/CreditCard";
import Boleto from "./paymentOptions/Boleto";
import Pix from "./paymentOptions/Pix";
import { updatePaymentMethod } from "../redux/orderSlice";
import { setTotal } from "../redux/cartSlice";

const CheckoutForm = ({ phase }) => {
  const dispatch = useDispatch();
  const [discountApplied, setDiscountApplied] = useState(false); // Local state to track if the discount has been applied
  const cart = useSelector((state) => state.cart.cart);
  const paymentMethod = useSelector(
    (state) => state.order.paymentDetails.paymentMethod
  );
  const paymentOptions = [
    { name: "PIX", value: "PIX" },
    { name: "Boleto", value: "BANK_SLIP" },
    { name: "Cartão de Crédito", value: "CREDIT_CARD" },
  ];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentOptions[0].value
  );

  useEffect(() => {
    let pMethod = { pMethod: selectedPaymentMethod.value };
    dispatch(updatePaymentMethod(pMethod));
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (paymentMethod === "PIX" && !discountApplied) {
      const discount = Number(cart.subTotal) * 0.05; // Use subTotal for discount calculation
      const newTotal = Number(cart.total) - discount;
      console.log(discount, newTotal);
      dispatch(setTotal(Number(newTotal.toFixed(2))));
      setDiscountApplied(true); // Set the flag to true to indicate discount has been applied
    } else if (paymentMethod !== "PIX" && discountApplied) {
      let s = cart.subTotal;
      let f = cart.frete;
      dispatch(setTotal(Number(s + f)));
      setDiscountApplied(false); // Reset the flag if changing payment method
    }
  }, [paymentMethod, discountApplied]);

  return (
    <div className="w-full space-y-4 p-4 sm:p-10 md:max-w-lg">
      <h3 className="text-h3 leading-h3 col-span-1 sm:col-span-4">
        Informações de Pagamento
      </h3>

      {phase === 2 ? (
        <>
          <div className=" mx-auto mt-2 bg-[#dff0d8] rounded-md p-4 text-center">
            <p>
              <span>Ganhe</span>{" "}
              <span className="text-[#00a470] font-bold">5%</span>{" "}
              <span>de desconto pagando via</span>{" "}
              <span className="text-[#00a470] font-bold">PIX!</span>
            </p>
          </div>
          <RadioGroup
            by="name"
            value={selectedPaymentMethod}
            onChange={setSelectedPaymentMethod}
            aria-label="Server size"
            className="space-y-4"
          >
            <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0">
              {paymentOptions.map((plan) => (
                <Radio key={plan.name} value={plan}>
                  {({ checked }) => (
                    <div
                      className={`flex items-center p-4 border rounded-lg cursor-pointer transition-all duration-200 ${
                        checked
                          ? "bg-black text-white border-black"
                          : "bg-white text-gray-800 border-gray-300"
                      }`}
                    >
                      <Label className="w-4 h-4 border-2 border-gray-300 rounded-full flex items-center justify-center">
                        {checked && (
                          <div className="w-2.5 h-2.5 bg-white rounded-full" />
                        )}
                      </Label>
                      <span className="ml-3">{plan.name}</span>
                    </div>
                  )}
                </Radio>
              ))}
            </div>
          </RadioGroup>
          {selectedPaymentMethod.value === "CREDIT_CARD" && <CreditCard />}
          {selectedPaymentMethod.value === "BANK_SLIP" && <Boleto />}
          {selectedPaymentMethod.value === "PIX" && <Pix />}
        </>
      ) : (
        <p className="text-gray-500">
          Complete os passos anteriores para liberar o pagamento
        </p>
      )}
    </div>
  );
};

export default CheckoutForm;
