import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import { updateHolderInfo, updatePaymentMethod } from "../../redux/orderSlice";
import { setResume } from "../../redux/resumeSlice";

const CreditCard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const [errorMessage, setErrorMessage] = useState(null);
  const order = useSelector((state) => state.order);
  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Inicia o carregamento
      setErrorMessage(null); // Reset error message

      // Atualize o método de pagamento como cartão de crédito
      dispatch(updatePaymentMethod({ pMethod: "CREDIT_CARD" }));
      dispatch(updateHolderInfo(user));

      // Separa mês e ano da data de validade
      const [expiryMonth, expiryYear] = data.expiryDate.split("/");

      // Cria o payload de pagamento com os dados do formulário e a ordem
      const paymentPayload = {
        ...order,
        paymentDetails: {
          ...order.paymentDetails,
          creditCardDetails: {
            holderName: data.cardName,
            number: data.cardNumber.replace(/\s/g, ""), // Remove espaços
            expiryMonth: expiryMonth,
            expiryYear: expiryYear,
            ccv: data.cvv,
            holderInfo: {
              addressComplement: user.billingAddress?.complement,
              addressNumber: user.billingAddress?.number.toString(),
              cpfCnpj: user.docNumber,
              email: user.email,
              name: user.name,
              phone: user.phone,
              postalCode: user.billingAddress?.zipCode,
            },
            paymentInfo: {
              installmentCount: Number(data.installmentCount),
              installmentValue: Number(cart.total / data.installmentCount),
            },
          },
        },
      };

      // Faz a requisição à API para enviar o pagamento
      const response = await api.post("/orders", paymentPayload);

      if (response.status === 201) {
        console.log("Pagamento realizado com sucesso!");
        dispatch(setResume(response.data.order));
      } else {
        throw new Error("Erro ao realizar a compra");
      }
    } catch (error) {
      console.error("Erro:", error);
      setErrorMessage("Erro ao realizar o pagamento. Tente novamente."); // Set error message
    } finally {
      setIsLoading(false);
    }
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  function getInstallmentOptions(total) {
    const options = [];

    if (total < 179) {
      options.push(
        ...[1, 2, 3, 4].map((n) => ({
          value: n,
          label: `${n}x de ${formatCurrency(total / n)} sem juros`, // Calculate the installment value
        }))
      );
    } else if (total <= 1000) {
      options.push(
        ...[1, 2, 3, 4, 5, 6].map((n) => ({
          value: n,
          label: `${n}x de ${formatCurrency(total / n)} sem juros`, // Calculate the installment value
        }))
      );
    } else {
      options.push(
        ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => ({
          value: n,
          label: `${n}x de ${formatCurrency(total / n)} sem juros`, // Calculate the installment value
        }))
      );
    }

    return options;
  }

  const installmentOptions = getInstallmentOptions(cart.total);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md space-y-4"
    >
      <h2 className="text-xl font-bold mb-2">
        Pagamento com Cartão de Crédito
      </h2>

      {errorMessage && <div className="text-red-500">{errorMessage}</div>}

      <div className="flex flex-col">
        <label
          htmlFor="cardNumber"
          className="text-sm font-medium text-gray-700"
        >
          Número do Cartão
        </label>
        <input
          type="text"
          id="cardNumber"
          placeholder="1234 5678 9012 3456"
          {...register("cardNumber", {
            required: "Número do cartão é obrigatório",
            pattern: {
              value: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/,
              message: "Número do cartão inválido",
            },
          })}
          onInput={(e) => {
            // Impedir que o usuário insira mais do que 19 caracteres (16 números + 3 espaços)
            if (e.target.value.length > 19) {
              e.target.value = e.target.value.slice(0, 19);
            }

            // Adiciona o espaço automaticamente após cada 4 dígitos
            const value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
            const formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1 "); // Adiciona espaços a cada 4 dígitos
            e.target.value = formattedValue.trim(); // Atualiza o valor do input
          }}
          className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-black"
        />

        {errors.cardNumber && (
          <span className="text-red-500 text-sm">
            {errors.cardNumber.message}
          </span>
        )}
      </div>

      <div className="flex flex-col">
        <label htmlFor="cardName" className="text-sm font-medium text-gray-700">
          Nome no Cartão
        </label>
        <input
          type="text"
          id="cardName"
          placeholder="Nome completo"
          {...register("cardName", {
            required: "Nome no cartão é obrigatório",
          })}
          className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-black"
        />
        {errors.cardName && (
          <span className="text-red-500 text-sm">
            {errors.cardName.message}
          </span>
        )}
      </div>

      <div className="flex flex-col">
        <label
          htmlFor="installment"
          className="text-sm font-medium text-gray-700"
        >
          Parcelamento
        </label>
        <select
          {...register("installmentCount")}
          id="installment"
          className="mt-1 p-2 border rounded-md cursor-pointer hover:ring-0 focus:ring-2 focus:ring-black outline-none" // Add outline-none here
        >
          {installmentOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex space-x-4">
        <div className="flex-1 flex flex-col">
          <label
            htmlFor="expiryDate"
            className="text-sm font-medium text-gray-700"
          >
            Validade
          </label>
          <input
            type="text"
            id="expiryDate"
            placeholder="MM/AA"
            {...register("expiryDate", {
              required: "Data de validade é obrigatória",
              pattern: {
                value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                message: "Formato inválido. Use MM/AA.",
              },
              validate: (value) => {
                const [month, year] = value.split("/");
                const isValidDate =
                  month >= 1 &&
                  month <= 12 &&
                  year.length === 2 &&
                  new Date(`20${year}`, month - 1).getTime() > Date.now(); // Verifica se a data não é passada
                return isValidDate || "Data de validade vencida.";
              },
            })}
            onInput={(e) => {
              // Impedir que o usuário insira mais do que 5 caracteres (MM/AA)
              if (e.target.value.length > 5) {
                e.target.value = e.target.value.slice(0, 5);
              }

              // Adiciona a barra automaticamente após dois dígitos
              const value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
              if (value.length >= 2 && !value.includes("/")) {
                e.target.value = value.slice(0, 2) + "/" + value.slice(2, 4);
              } else {
                e.target.value = value;
              }
            }}
            className="mt-1 p-2 border rounded-md focus:outline-none "
          />

          {errors.expiryDate && (
            <span className="text-red-500 text-sm">
              {errors.expiryDate.message}
            </span>
          )}
        </div>

        <div className="flex-1 flex flex-col">
          <label htmlFor="cvv" className="text-sm font-medium text-gray-700">
            CVV
          </label>
          <input
            type="text"
            id="cvv"
            placeholder="123"
            {...register("cvv", {
              required: "CVV é obrigatório",
              pattern: {
                value: /^[0-9]{3,4}$/,
                message: "CVV inválido",
              },
            })}
            onInput={(e) => {
              // Impedir que o usuário insira mais do que 3 caracteres CVV
              if (e.target.value.length > 3) {
                e.target.value = e.target.value.slice(0, 3);
              }
            }}
            className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-black max-w-20"
          />
          {errors.cvv && (
            <span className="text-red-500 text-sm">{errors.cvv.message}</span>
          )}
        </div>
      </div>

      <button
        type="submit"
        disabled={isLoading || cart.items.length === 0} // Desativa o botão durante o carregamento
        className={`w-full bg-black text-white font-bold py-2 rounded-md 
          hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-black ${
            isLoading || cart.items.length === 0
              ? "cursor-not-allowed opacity-70"
              : ""
          }`}
      >
        {isLoading ? (
          <svg
            className="animate-spin h-5 w-5 text-white mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8z"
            ></path>
          </svg>
        ) : cart.items.length === 0 ? (
          "Carrinho Vazio"
        ) : (
          "Pagar"
        )}
      </button>
    </form>
  );
};

export default CreditCard;
