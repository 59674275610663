import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoginModal from "./Login/LoginModal";

const UserProfile = ({ phase }) => {
  const user = useSelector((state) => state.user?.user);
  const status = useSelector((state) => state.user?.status);
  const holderInfo = useSelector(
    (state) => state.order?.paymentDetails?.creditCardDetails?.holderInfo
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (status === "loading") {
    return <div className="p-4">Carregando dados do usuário...</div>;
  }

  const formatPhoneNumberForDisplay = (number) => {
    const cleaned = ("" + number).replace(/\D/g, "");
    let formatted = "";

    if (cleaned.length <= 2) {
      formatted = `(${cleaned}`;
    } else if (cleaned.length <= 6) {
      formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
    } else if (cleaned.length === 10) {
      // Formato de número fixo: (XX) XXXX-XXXX
      formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
        2,
        6
      )}-${cleaned.slice(6)}`;
    } else if (cleaned.length === 11) {
      // Formato de número móvel: (XX) 9XXXX-XXXX
      formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
        2,
        7
      )}-${cleaned.slice(7)}`;
    } else {
      // Retorna o número "limpo" se exceder 11 dígitos
      formatted = cleaned;
    }

    return formatted;
  };

  const moveToZP = () => {
    window.location.href = "https://www.zpcosmeticos.com.br/cadastro";
  };

  return (
    <div className="w-full md:max-w-lg">
      {phase >= 0 ? (
        user ? (
          <div className="w-full space-y-4 p-4 sm:p-10 md:max-w-lg">
            <h3 className="text-h3 leading-h3 col-span-1 sm:col-span-4">
              Informações de Contato
            </h3>
            <div className="p-4">
              <h1>
                Bem-vindo, <span className="underline">{user.name}</span>
              </h1>
              <p>Email: {user.email}</p>
              <p>Telefone: {formatPhoneNumberForDisplay(user.phone)}</p>
            </div>
          </div>
        ) : (
          <div className="w-full p-4 sm:p-10">
            <div className="space-y-6">
              <h3 className="text-h3 leading-h3 col-span-1 sm:col-span-4">
                Informações de Contato
              </h3>
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded"
              >
                Acessar sua conta ZP
              </button>
              <p className="text-sm text-gray-500 ">
                <span
                  className="cursor-pointer hover:underline"
                  onClick={() => moveToZP()}
                >
                  Criar uma nova conta
                </span>
                <span className="font-semibold text-xs">
                  (Você será redirecionado para a página de cadastro no site da
                  ZPCosméticos.)
                </span>
              </p>
            </div>
            <LoginModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
        )
      ) : (
        holderInfo && (
          <div className="w-full space-y-6 p-4 sm:p-10">
            <h3 className="text-h3 leading-h3 col-span-1 sm:col-span-4">
              Informações de Contato
            </h3>
            <ul className="p-4">
              <li>Nome: {user.name}</li>
              <li>Email: {user.email}</li>
              <li>Contato: {formatPhoneNumberForDisplay(user.phone)}</li>
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default UserProfile;
