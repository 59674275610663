import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import api from "../api/api";

export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (isCompany, { rejectWithValue }) => {
    try {
      const cart = Cookies.get("cartData");

      if (!cart) {
        throw new Error("Carrinho não encontrado no cookie");
      }

      const cartItems = JSON.parse(cart);
      const response = await api.get(`/products?take=500`);
      const products = response.data.products;
      const currentDate = new Date();

      const items = cartItems
        .map((cartItem) => {
          const product = products.find(
            (product) => product.id === cartItem.id
          );
          if (product) {
            const { id, ...productWithoutId } = product;

            let price;
            if (!isCompany) {
              price =
                product.customerPriceNew &&
                new Date(product.discountCustomerDueDate) >= currentDate
                  ? product.customerPriceNew
                  : product.customerPrice;
            } else {
              price =
                product.vendorPriceNew &&
                new Date(product.discountVendorDueDate) >= currentDate
                  ? product.vendorPriceNew
                  : product.vendorPrice;
            }

            return {
              ...productWithoutId,
              orderProductId: id,
              quantity: cartItem.qt,
              price,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      return items;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  cart: { items: [], subTotal: 0, frete: null, cupom: 0, total: 0 },
  status: "idle",
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      state.cart.items = action.payload;
    },
    setFrete(state, action) {
      state.cart.frete = action.payload;
    },
    setSubTotal(state, action) {
      state.cart.subTotal = action.payload;
    },
    setTotal(state, action) {
      state.cart.total = action.payload;
    },
    addItem(state, action) {
      const existingItem = state.cart.items.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.items.push({ ...action.payload, quantity: 1 });
      }
    },
    removeItem(state, action) {
      state.cart.items = state.cart.items.filter(
        (item) => item.orderProductId !== action.payload
      );
    },
    increaseQuantity(state, action) {
      const item = state.cart.items.find(
        (item) => item.orderProductId === action.payload
      );
      if (item) {
        item.quantity += 1;
      }
    },
    decreaseQuantity(state, action) {
      const item = state.cart.items.find(
        (item) => item.orderProductId === action.payload
      );
      if (item) {
        if (item.quantity > 1) {
          item.quantity -= 1;
        } else {
          state.cart.items = state.cart.items.filter(
            (item) => item.orderProductId !== action.payload
          );
        }
      }
    },
    clearCart(state) {
      state.cart.items = [];
      state.cart.subTotal = 0;
      state.cart.total = 0;
      state.cart.frete = 0;

      // Remove cartData from cookies
      Cookies.remove("cartData");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cart.items = action.payload; // Store cart items
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.status = "failed";
        state.error =
          action.payload || "Não foi possível carregar os dados do carrinho.";
      });
  },
});

// Function to calculate the total weight
export const getTotalWeight = (state) => {
  const items = state.cart.cart.items;

  if (!items || !Array.isArray(items)) {
    return 0;
  }

  return items.reduce((total, item) => total + item.weight * item.quantity, 0);
};

export const {
  setCart,
  setFrete,
  setSubTotal,
  setTotal,
  addItem,
  removeItem,
  increaseQuantity,
  decreaseQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
