import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";
import Cookies from "js-cookie";

// Async thunk para buscar os dados do usuário pela API
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      // Pega o token JWT do cookie
      const token = Cookies.get("token");

      // Verifica se o token existe no cookie
      if (!token) {
        throw new Error("Token não encontrado no cookie");
      }

      // Define o token JWT no cabeçalho da API
      api.defaults.headers.Authorization = `Bearer ${token}`;

      // Faz a requisição à API para buscar os dados do usuário
      const response = await api.get(`/me`);

      return response.data; // Retorna os dados do usuário
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  user: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser(state) {
      state.user = null;
      state.status = "idle";
      state.error = null;
      Cookies.remove("token");
    },
    setUser(state, action) {
      state.user = action.payload;
      state.status = "succeeded";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload; // Armazena os dados do usuário no estado global
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error =
          action.payload || "Não foi possível carregar os dados do usuário.";
      });
  },
});

// Selector para obter a propriedade isCompany
export const selectIsCompany = (state) => state.user.user?.isCompany || false;

export const { clearUser, setUser } = userSlice.actions;
export default userSlice.reducer;
